<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Section caractéristiques générales-->
			<div class="container caracteristiquesGeneralesProjet">
				<div class="row">
					<!--Le commanditaire du projet-->
					<div
						class="commanditaireProjet col-sm-12 col-md-12 col-lg-4 col-xl-4"
					>
						<div>
							<h2>COMMANDITAIRE</h2>
							<p>Travail pédagogique pour le DUT MMI</p>
						</div>
					</div>

					<!--La date de réalisation du projet-->
					<div
						class="dateProjet offset-lg-1 offset-xl-1 col-sm-12 col-md-12 col-lg-3 col-xl-3"
					>
						<div>
							<h2>ANNÉES</h2>
							<p>2019</p>
						</div>
					</div>

					<!--Les services apportés pour ce projet-->
					<div class="servicesProjet col-sm-12 col-md-12 col-lg-4 col-xl-4">
						<div>
							<h2>SERVICES</h2>
							<p>
								Design, animation
							</p>
						</div>
					</div>
				</div>
			</div>

			<!--Section présentation du projet-->
			<div class="container">
				<div class="row">
					<p class="contenuProjet col-sm-12 col-md-12 col-lg-12 col-xl-12">
						J'ai réalisé ce projet lors de mon second semestre en DUT Métiers du
						Multimédia et de l'Internet dans le cadre d'un exercice pédagogique.
						Il nous a été demandé pour cela de créer une ambiance et de choisir
						trois batiments emblématiques de Belfort et Montbéliard à
						représenter sur le logiciel Adobe Illustrator. <br /><br />J'ai
						décidé pour ma part de représenter l'église Saint-Joseph de Belfort,
						la cathédrale Saint-Christophe de Belfort et l'église
						Saint-Maimboeuf de Montbéliard dans une ambiance festival. Par la
						suite, une fois l'illustration terminée nous avons pu l'animer à
						notre plein gré grâce au format SVG pour amplifier l'immersion.
					</p>

					<!--Vidéo 1-->

					<video
						class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
						autoplay
						loop
						style="width: 100%"
					>
						<!-- <source
							src="../assets/monumentsbelfortmontbeliard/animation_video.ogv"
							type="video/ogg"
						/> -->
						<source
							src="../assets/monumentsbelfortmontbeliard/animation_video.webm"
							type="video/webm"
						/>
						<source
							src="../assets/monumentsbelfortmontbeliard/animation_video.mp4"
							type="video/mp4"
						/>
					</video>

					<p class="descriptionImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						Animation finale de l'illustration avec l'église Saint-Joseph à
						gauche, la cathédrale Saint-Christophe au centre et l'église
						Saint-Maimboeuf à droite
					</p>
				</div>
			</div>

			<div class="container">
				<!--Bouton voir les autres projets-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
						<router-link to="projets" class="boutonVoirTousLesProjets"
							>VOIR LES AUTRES PROJETS</router-link
						>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Monuments de Belfort et Montbéliard",
			meta: [
				{
					name: "description",
					content:
						"Découvrez le projet d'illustration des Monuments de Belfort et Montbéliard réalisé par Antonin Winterstein !",
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "MONUMENTS DE BELFORT ET MONTBÉLIARD - ILLUSTRATION",
				urlImage: require("../assets/projets/fond_monuments_belfort_montbeliard.svg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style>
@import "../styles/pageProjet.css";
</style>
